<template>
    <div class="login-container">
      <v-card width="350px" class="login-card">
          <v-container class="left-login-form text-center">
             <img src="img/logo.png" alt="" width="50">
            <h3 class="text-center">SIGN IN</h3>
            <v-alert
            dense
            outlined
            type="error"
            v-if="pesan_error"
            small
          >
           {{pesan_error}}
          </v-alert>
            <v-form
                ref="form"
                lazy-validation
                v-model="valid"
            >
                <v-text-field
                v-model="phone"
                label="Phone"
                required
                prepend-icon="smartphone"
                @keydown.enter="login()"
                ></v-text-field>
                <v-text-field
                v-model="password"
                type="password"
                label="Password"
                prepend-icon="lock"
                required
                @keydown.enter="login()"

                ></v-text-field>
                <br>
                <v-btn
                color="primary"
                class="mr-4 white--text"
                rounded
                block
                :disabled="!valid"
                :loading="loading"
                @click="login"
                >
                SIGN IN
                </v-btn>
            </v-form>
          </v-container>

          <v-container class="right-login-form text-center">
            <h3 class="text-center"></h3>
            <h4 class="text-center"></h4>
            <img src="img/login_bg.svg" alt="" style="width:100%;opacity:0.5">
          </v-container>
      </v-card>
    </div>
</template>

<script>
import mixinAuth from './mixin.js'
import store from '../../../store/index.js'
import axios from 'axios'
export default {
  mixins: [mixinAuth],
  async beforeRouteEnter (to, from, next) {
    // console.log(to)
    // console.log(from)
    // console.log(next)
    let user = localStorage.getItem('user')

    if (user) {
      user = await JSON.parse(user)
      const data = new URLSearchParams()
      data.append('phone', user.phone)
      data.append('password', user.password)
      await axios.post(process.env.VUE_APP_APIHOST + '/v1/auth/user', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(async (ress) => {
          const new_user = ress.data
          await store.dispatch('auth/setUser', { ...new_user, password: user.password, phone: user.phone })
          await store.dispatch('auth/setToken', new_user.token)
          await localStorage.setItem('user', JSON.stringify({...new_user,password: user.password, phone: user.phone}))
          next('login')
        }
        )
        .catch((err) => console.log(err))
    }
    next()
  }
}
</script>

<style lang="css" scoped>
.v-main {
  background-color: #4c8dff !important;
}
.login-container {
  height:550px; display:flex; justify-content: center;align-items: center;
}
 .login-card{
   margin: auto;
   padding: 20px;
 }
</style>
