import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    phone: '',
    descriptionRules: [
      v => !!v || 'Tidak Boleh Kosong'
    ],
    password: '',
    pesan_error: ''
  }),
  methods: {
    ...mapActions({
      setUser: 'auth/setUser',
      setToken: 'auth/setToken'
    }),

    async login () {
      this.loading = true
      this.pesan_error = ''
      const data = new URLSearchParams()
      data.append('phone', this.phone)
      data.append('password', this.password)
      await this.axios.post('v1/auth/user', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(async (ress) => {
          console.log(ress)
          const user = ress.data
          this.setToken(ress.data.token)
          await this.setUser({ ...user, password: this.password, phone: this.phone })
          this.$router.push( this.beforeUrl ? this.beforeUrl : '/dashboard')
          localStorage.setItem('user', JSON.stringify(this.user))
        })
        .catch((err) => {
          this.pesan_error = 'Email or Pasword Is Incorrect'
        })
      this.loading = false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      beforeUrl:'beforeUrl'
    })
  }
}
